import { CircularProgress } from "@mui/material";

const LoadingOverlay = () => (
    <div style={{
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        width: '100vw',
        height: '100vh',
        zIndex: 1000,
    }}
    >
        <CircularProgress />
    </div>
);

export default LoadingOverlay;
